var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_work_order") } },
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c("a-col", { attrs: { span: 24 } }, [
            _c(
              "fieldset",
              [
                _c("legend", [
                  _vm._v(" " + _vm._s(_vm.$t("lbl_general")) + " ")
                ]),
                _c(
                  "a-form-model",
                  {
                    ref: "formModel",
                    attrs: {
                      model: _vm.formData,
                      rules: _vm.formRules,
                      "label-align": "left",
                      "wrapper-col": { span: 12 },
                      "label-col": { span: 9 }
                    }
                  },
                  [
                    _c(
                      "a-row",
                      { attrs: { gutter: [16, 16] } },
                      [
                        _c(
                          "a-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  label: _vm.$t("lbl_branch"),
                                  prop: "branch",
                                  "data-testid": "srf-create-wo-branch"
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.branchName) + " ")]
                            ),
                            _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  label: _vm.$t("lbl_wo_date"),
                                  prop: "workOrderDate",
                                  "data-testid": "srf-create-wo-date"
                                }
                              },
                              [
                                _c("a-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: { format: _vm.DEFAULT_DATE_FORMAT },
                                  model: {
                                    value: _vm.formData.workOrderDate,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "workOrderDate",
                                        $$v
                                      )
                                    },
                                    expression: "formData.workOrderDate"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  label: _vm.$t("lbl_schedule_order"),
                                  prop: "scheduleOrderDate",
                                  "data-testid":
                                    "srf-create-wo-schedule-order-date"
                                }
                              },
                              [
                                _c("a-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    format: _vm.DEFAULT_DATE_FORMAT,
                                    "disabled-date": _vm.useDisabledBackDate
                                  },
                                  model: {
                                    value: _vm.formData.scheduleOrderDate,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "scheduleOrderDate",
                                        $$v
                                      )
                                    },
                                    expression: "formData.scheduleOrderDate"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  label: _vm.$t("lbl_mechanic_name"),
                                  prop: "mechanic",
                                  "data-testid": "srf-create-wo-mechanic"
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.mechanicName) + " ")]
                            ),
                            _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  label: _vm.$t("lbl_description"),
                                  prop: "description",
                                  "data-testid": "srf-create-wo-description"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "extra",
                                    fn: function() {
                                      return [
                                        _c("character-length", {
                                          attrs: {
                                            value: _vm.formData.description
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              },
                              [
                                _c("a-textarea", {
                                  attrs: {
                                    placeholder: _vm.$t("common.insert-text", {
                                      text: _vm.$t("lbl_description")
                                    })
                                  },
                                  model: {
                                    value: _vm.formData.description,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formData, "description", $$v)
                                    },
                                    expression: "formData.description"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  label: _vm.$t("lbl_customer_name"),
                                  prop: "customer",
                                  "data-testid": "srf-create-wo-customer"
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.customerName) + " ")]
                            ),
                            _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  label: _vm.$t("lbl_customer_pic_name"),
                                  "data-testid": "srf-create-wo-pic-name"
                                }
                              },
                              [
                                _c("a-input", {
                                  attrs: {
                                    placeholder: _vm.$t("common.insert-text", {
                                      text: _vm.$t("lbl_customer_pic_name")
                                    }),
                                    "allow-clear": ""
                                  },
                                  model: {
                                    value: _vm.formData.customerPICName,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "customerPICName",
                                        $$v
                                      )
                                    },
                                    expression: "formData.customerPICName"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  label: _vm.$t("lbl_document_number"),
                                  prop: "documentNumber",
                                  "data-testid": "srf-create-wo-document-number"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.formData.documentNumber) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  label: _vm.$t("lbl_customer_address"),
                                  prop: "customerAddress",
                                  "data-testid":
                                    "srf-create-wo-customer-address"
                                }
                              },
                              [
                                _c("a-select", {
                                  attrs: {
                                    options: _vm.customerAddressOptions,
                                    placeholder: _vm.$t("common.select-text", {
                                      text: _vm.$t("lbl_customer_address")
                                    }),
                                    "label-in-value": ""
                                  },
                                  model: {
                                    value: _vm.formData.customerAddress,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "customerAddress",
                                        $$v
                                      )
                                    },
                                    expression: "formData.customerAddress"
                                  }
                                }),
                                _c(
                                  "a-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      block: "",
                                      "data-testid":
                                        "srf-create-wo-btn-generate"
                                    },
                                    on: { click: _vm.handleGenerate }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("lbl_generate")) + " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("a-col", { attrs: { span: 24 } }, [
            _c(
              "fieldset",
              [
                _c("legend", [_vm._v(" " + _vm._s(_vm.$t("lbl_unit")) + " ")]),
                _c("a-table", {
                  attrs: {
                    size: "small",
                    "row-key": "id",
                    columns: _vm.unitColumns,
                    "data-source": _vm.formData.units,
                    "row-class-name": function(_record, index) {
                      return index % 2 ? "bg-white" : "bg-gray-light"
                    },
                    scroll: { y: 600, x: "calc(100% + 200px)" },
                    "data-testid": "srf-create-wo-table-units",
                    "row-selection": {
                      selectedRowKeys: _vm.selectedUnitRowKeys,
                      onChange: _vm.onSelectedUnitRowChange
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _c("a-button", {
                            attrs: { icon: "delete", type: "danger" },
                            on: { click: _vm.handleDeleteUnitRows }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ])
                })
              ],
              1
            )
          ]),
          _c("a-col", { attrs: { span: 24 } }, [
            _c(
              "fieldset",
              [
                _c("legend", [
                  _vm._v(" " + _vm._s(_vm.$t("lbl_sparepart")) + " ")
                ]),
                _c("a-table", {
                  attrs: {
                    size: "small",
                    "row-key": "id",
                    columns: _vm.partColumns,
                    "data-source": _vm.formData.spareParts,
                    "row-class-name": function(_record, index) {
                      return index % 2 ? "bg-white" : "bg-gray-light"
                    },
                    scroll: { y: 600, x: "calc(100% + 200px)" },
                    "data-testid": "srf-create-wo-table-spare-parts",
                    "row-selection": {
                      selectedRowKeys: _vm.selectedPartRowKeys,
                      onChange: _vm.onSelectedPartRowChange
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _c("a-button", {
                            attrs: { icon: "delete", type: "danger" },
                            on: { click: _vm.handleDeleteSparePartRows }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "partNumber",
                      fn: function(_, record) {
                        return [
                          _c("a-select", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "allow-clear": "",
                              "show-search": "",
                              "filter-option": false,
                              loading:
                                _vm.loading.fetchPartNumber ||
                                record.loadingPartNumbers,
                              options: record.useLocalPartNumberOptions
                                ? record.partNumberOptions
                                : _vm.partNumberOptions,
                              placeholder: _vm.$t("common.select-text", {
                                text: _vm.$t("lbl_part")
                              }),
                              "dropdown-match-select-width": false,
                              "label-in-value": ""
                            },
                            on: {
                              search: function($event) {
                                return _vm.handlePartSearch(
                                  "code",
                                  record,
                                  $event
                                )
                              },
                              change: function($event) {
                                return _vm.handlePartChange(record, $event)
                              }
                            },
                            model: {
                              value: record.partNumber,
                              callback: function($$v) {
                                _vm.$set(record, "partNumber", $$v)
                              },
                              expression: "record.partNumber"
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "partName",
                      fn: function(_, record) {
                        return [
                          _c("a-select", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "allow-clear": "",
                              "show-search": "",
                              "filter-option": false,
                              loading:
                                _vm.loading.fetchPartName ||
                                record.loadingPartNames,
                              options: record.useLocalPartNameOptions
                                ? record.partNameOptions
                                : _vm.partNameOptions,
                              placeholder: _vm.$t("common.select-text", {
                                text: _vm.$t("lbl_part")
                              }),
                              "dropdown-match-select-width": false,
                              "label-in-value": ""
                            },
                            on: {
                              search: function($event) {
                                return _vm.handlePartSearch(
                                  "name",
                                  record,
                                  $event
                                )
                              },
                              change: function($event) {
                                return _vm.handlePartChange(record, $event)
                              }
                            },
                            model: {
                              value: record.partName,
                              callback: function($$v) {
                                _vm.$set(record, "partName", $$v)
                              },
                              expression: "record.partName"
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "uom",
                      fn: function(_, record) {
                        return [
                          _c("SelectPartUom", {
                            staticClass: "w-100",
                            attrs: {
                              "product-id": record.partNumber
                                ? record.partNumber.key
                                : ""
                            },
                            on: {
                              "update:meta": function($event) {
                                return _vm.handleUOMChange(record)
                              }
                            },
                            model: {
                              value: record.uom,
                              callback: function($$v) {
                                _vm.$set(record, "uom", $$v)
                              },
                              expression: "record.uom"
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "partLocation",
                      fn: function(_, record) {
                        return [
                          _c("SelectPartLocation", {
                            staticClass: "w-100",
                            attrs: {
                              "branch-id": _vm.formData.branch
                                ? _vm.formData.branch.key
                                : "",
                              "product-id": record.partNumber
                                ? record.partNumber.key
                                : "",
                              "uom-id": record.uom ? record.uom.key : ""
                            },
                            on: {
                              "update:meta": function(ev) {
                                return _vm.handlePartLocationChange(record, ev)
                              }
                            },
                            model: {
                              value: record.partLocation,
                              callback: function($$v) {
                                _vm.$set(record, "partLocation", $$v)
                              },
                              expression: "record.partLocation"
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "qty",
                      fn: function(_, record) {
                        return [
                          _c("a-input-number", {
                            model: {
                              value: record.qty,
                              callback: function($$v) {
                                _vm.$set(record, "qty", $$v)
                              },
                              expression: "record.qty"
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "notes",
                      fn: function(_, record) {
                        return [
                          _c("a-textarea", {
                            attrs: {
                              "auto-size": "",
                              placeholder: _vm.$t("common.insert-text", {
                                text: _vm.$t("lbl_notes")
                              })
                            },
                            model: {
                              value: record.notes,
                              callback: function($$v) {
                                _vm.$set(record, "notes", $$v)
                              },
                              expression: "record.notes"
                            }
                          }),
                          _c("character-length", {
                            attrs: { value: record.notes }
                          })
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ]),
          _c(
            "a-col",
            { attrs: { span: 24, align: "end" } },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { "data-testid": "srf-create-wo-btn-back" },
                      on: { click: _vm.handleBack }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")]
                  ),
                  _vm.$can("create", "work-order")
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loading.submit,
                            "data-testid": "srf-create-wo-btn-submit"
                          },
                          on: { click: _vm.handleSubmit }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_submit")) + " ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }